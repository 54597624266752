import axiosClient from './axiosClient';

const HistoryAPI = {
	getHistoryAPI: () => {
		// const url = `/histories${query}`;
		const url = `/histories`;
		return axiosClient.get(url);
	},

	getDetail: (id) => {
		const url = `/histories/${id}`;
		return axiosClient.get(url);
	},
};

export default HistoryAPI;
