import banner from './banner1.jpg';
import img1 from './product_1.png';
import img2 from './product_2.png';
import img3 from './product_3.png';
import img4 from './product_4.png';
import img5 from './product_5.png';

const Image = {
	banner: banner,
	img1: img1,
	img2: img2,
	img3: img3,
	img4: img4,
	img5: img5,
};

export default Image;
